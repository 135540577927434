<template>
  <p class="flex gap-1 items-end whitespace-nowrap">
    <span
      class="font-bold align-bottom"
      :style="{ fontSize: `${size}px`, lineHeight: `${size}px` }"
      >{{ formattedValue }}</span
    >
    <span :style="{ fontSize: `${Math.max(size / 2, 12)}px` }">/{{ max }}</span>
  </p>
</template>

<script lang="ts" setup>
const properties = withDefaults(
  defineProps<{
    value: number;
    size?: number;
    max?: number;
  }>(),
  {
    size: 24,
    max: 5,
  },
);

const formattedValue = useI18nNumber(properties.value, {
  maximumFractionDigits: 1,
});
</script>
